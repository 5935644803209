<template>
  <span class="add-invoice">
    <slot>
      <el-button @click="show" size="small" type="primary">新增地址</el-button>
    </slot>
    <el-dialog
      :before-close="handleClose"
      :title="name"
      :visible.sync="dialogFormVisible"
      center
      width="600px"
      :close-on-click-modal="false"
    >
      <el-form
        :disabled="type === 'order-details'"
        :model="form"
        :rules="rules"
        label-width="110px"
        ref="ruleForm"
        v-loading="loading"
      >
        <!-- <div class="text">1、开票信息只可填写一次，提交后不可修改，如需修改请联系专属顾问。</div> -->
        <el-form-item label="开票状态" v-show="type === 'order-details'">
          <el-input v-model="statusName"></el-input>
        </el-form-item>
        <el-form-item label="发票类型" prop="invoiceType">
          <el-radio-group v-model="form.invoiceType" @change="changeInvoiceType">
            <el-radio label="PLAIN_VAT_INVOICE">增值税普通发票</el-radio>
            <el-radio label="SPECIAL_VAT_INVOICE">增值税专用发票</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 只有增值税普通发票可以选择个人 -->
        <el-form-item label="抬头类型" prop="titleType">
          <el-radio-group v-model="form.titleType" @change="changeTitleType">
            <el-radio label="ENTERPRISE">企业单位</el-radio>
            <el-radio label="PERSONAL" v-if="form.invoiceType === 'PLAIN_VAT_INVOICE'"
              >个人/非企业单位</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="发票抬头" prop="title">
          <el-input v-model.trim="form.title" placeholder="请填写发票抬头，个人填写姓名"></el-input>
          <el-button
            v-if="type !== 'order-details' && form.titleType !== 'PERSONAL'"
            :loading="saveLoading"
            @click="getTaxNo(form.title)"
            type="primary"
            >获取税号信息</el-button
          >
        </el-form-item>
        <div v-if="form.titleType === 'ENTERPRISE'">
          <el-form-item label="税号" prop="taxNo">
            <el-input v-model="form.taxNo" placeholder="请填写税号"></el-input>
          </el-form-item>
          <el-form-item
            label="开户银行"
            prop="bankName"
            :rules="[
              {
                required: form.invoiceType === 'SPECIAL_VAT_INVOICE' ? true : false,
                message: '必填信息',
                trigger: 'blur',
              },
            ]"
          >
            <el-input v-model.trim="form.bankName" placeholder="请输入开户银行"></el-input>
          </el-form-item>
          <el-form-item
            label="银行账号"
            prop="bankAccount"
            :rules="[
              {
                required: form.invoiceType === 'SPECIAL_VAT_INVOICE' ? true : false,
                message: '必填信息',
                trigger: 'blur',
              },
            ]"
          >
            <el-input v-model.trim="form.bankAccount" placeholder="请输入银行账号"></el-input>
          </el-form-item>
          <el-form-item
            label="企业电话"
            prop="mobile"
            :rules="[
              {
                required: form.invoiceType === 'SPECIAL_VAT_INVOICE' ? true : false,
                message: '必填信息',
                trigger: 'blur',
              },
            ]"
          >
            <el-input v-model.trim="form.mobile" placeholder="请输入企业电话"></el-input>
          </el-form-item>
          <el-form-item
            label="企业地址"
            prop="address"
            :rules="[
              {
                required: form.invoiceType === 'SPECIAL_VAT_INVOICE' ? true : false,
                message: '必填信息',
                trigger: 'blur',
              },
            ]"
          >
            <el-input v-model.trim="form.address" placeholder="请输入企业地址"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="联系姓名" prop="contactName">
          <el-input v-model.trim="form.contactName" placeholder="请输入联系姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系人邮箱" prop="email">
          <el-input v-model.trim="form.email" placeholder="用于接收电子发票"></el-input>
        </el-form-item>
        <el-form-item label="联系人手机号" prop="contactPhone">
          <el-input v-model.trim="form.contactPhone" placeholder="请输入联系人手机号"></el-input>
        </el-form-item>
        <el-form-item label="收件地址" prop="value" v-if="type !== 'order-details'">
          <AreaCascader
            class="area-width"
            v-model="form.value"
            placeholder="如果需要纸质发票，请输入邮寄地址"
            :key="key"
          />
        </el-form-item>
        <el-form-item label="详细地址" prop="contactAddress">
          <el-input v-model.trim="form.contactAddress" placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item label="客户备注" v-if="type === 'order-details' && form.description">
          <el-input v-model.trim="form.description"></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer" v-if="type === 'order-details'">
        <el-button @click="cancel" type="primary">确 定</el-button>
      </div>
      <div class="dialog-footer" slot="footer" v-else>
        <el-button @click="cancel">取 消</el-button>
        <el-button @click="submit('ruleForm')" type="primary">确 定</el-button>
      </div>
    </el-dialog>
  </span>
</template>

<script>
import AreaCascader from '@/components/AreaCascader';
import { create, update, getById, recordDeatil, queryInvoiceTitleInfoByName } from '@/api/invoice';
import { validatePhoneLoose, validateEmail } from '@/utils/validate';
import pick from 'lodash/pick';
export default {
  name: 'AddInvoice',
  components: {
    AreaCascader,
  },
  props: {
    id: String,
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(rule.required ? '必填信息' : void 0);
      } else {
        validatePhoneLoose(rule, value, callback);
      }
    };
    const validteEmailAdress = (rule, value, callback) => {
      if (!value) {
        callback(rule.required ? '必填信息' : void 0);
      } else {
        validateEmail(rule, value, callback);
      }
    };
    return {
      key: 1,
      dialogFormVisible: false,
      name: '新增发票抬头',
      form: {
        invoiceType: 'PLAIN_VAT_INVOICE',
        titleType: 'ENTERPRISE',
        title: '',
        taxNo: '',
        bankName: '',
        bankAccount: '',
        address: '',
        mobile: '',
        contactName: '',
        email: '',
        contactPhone: '',
        value: null,
        contactAddress: '',
      },
      statusName: '',
      saveLoading: false,
      loading: false,
      rules: {
        invoiceType: [{ required: true, message: '必填信息', trigger: 'blur' }],
        titleType: [{ required: true, message: '必填信息', trigger: 'blur' }],
        title: [{ required: true, message: '必填信息', trigger: 'blur' }],
        taxNo: [{ required: true, message: '必填信息', trigger: 'blur' }],
        contactName: [{ required: true, message: '必填信息', trigger: 'blur' }],
        email: [{ required: true, validator: validteEmailAdress, trigger: 'blur' }],
        contactPhone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
        value: [{ required: true, message: '必填信息', trigger: 'change' }],
        contactAddress: [{ required: true, message: '必填信息', trigger: 'blur' }],
      },
    };
  },
  methods: {
    // 如果发票类型为增值税普通发票，抬头类型只能为企业单位
    changeInvoiceType(val) {
      if (val === 'SPECIAL_VAT_INVOICE') {
        this.form.titleType = 'ENTERPRISE';
      }
    },
    // 抬头类型为为个人，则不需要企业的相关信息
    changeTitleType(titleType) {
      if (titleType === 'PERSONAL') {
        Object.assign(this.form, {
          taxNo: '',
          bankName: '',
          bankAccount: '',
          mobile: '',
          address: '',
        });
      }
    },
    show() {
      this.dialogFormVisible = true;
      this.initData();
    },
    cancel() {
      this.handleClose();
    },
    handleClose() {
      this.dialogFormVisible = false;
      this.$refs['ruleForm'].resetFields();
      for (const i in this.form) {
        this.form[i] = '';
      }
      this.form.value = [];
      this.form.invoiceType = 'PLAIN_VAT_INVOICE';
      this.form.titleType = 'ENTERPRISE';
      this.key++;
    },
    initData() {
      if (this.type === 'order-details') {
        this.name = '发票信息';
        this.recordDeatil();
      } else if (this.type === 'details') {
        this.name = '查看发票抬头';
        this.fetchAddInvoice();
      }
    },
    recordDeatil() {
      this.loading = true;
      recordDeatil(this.id)
        .then(res => {
          this.statusName = res.data.statusName;
          const data = res.data.invoiceTitleInfo;
          this.form = data;
          this.form.description = res.data.description;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchAddInvoice() {
      this.loading = true;
      getById(this.id)
        .then(res => {
          const data = res.data;
          if (data.contactAddressVO) {
            data.value = [
              data.contactAddressVO.provinceId,
              data.contactAddressVO.cityId,
              data.contactAddressVO.zoneId,
            ];
            data.contactAddress = data.contactAddressVO.addressDetail;
          }
          Object.assign(this.form, pick(res.data || {}, Object.keys(this.form)));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submit(form) {
      this.listLoading = true;
      this.$refs[form].validate(valid => {
        if (!valid) {
          return false;
        }
        const user = { ...this.form };
        if (user.value && user.value.length > 0) {
          const [provinceId, cityId, zoneId] = user.value;
          user.contactAddressCmd = {
            provinceId,
            cityId,
            zoneId,
            addressDetail: user.contactAddress,
          };
        } else if (user.contactAddressCmd) {
          delete user.contactAddressCmd;
        }

        if (this.id) {
          user.id = this.id;
        }
        user.isDefault = '1';
        ['value', 'contactAddressVO', 'contactAddress'].forEach(item => {
          delete user[item];
        });
        const request = this.id ? update : create;
        this.$confirm('确定保存吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            request(user).then(res => {
              this.$message.success('操作成功');
              if (!this.id) {
                user.id = res.data;
              }
              this.$emit('sureRefresh', user);
              this.handleClose();
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            });
          });
      });
    },
    getTaxNo(val) {
      this.saveLoading = true;
      if (val) {
        queryInvoiceTitleInfoByName(val)
          .then(res => {
            if (res.code === '0') {
              ['taxNo', 'bankName', 'address', 'bankAccount'].forEach(item => {
                this.form[item] = res.data[item];
              });
              this.form.mobile = res.data.contactPhone;
            }
          })
          .finally(() => {
            this.saveLoading = false;
          });
      } else {
        this.$message({
          message: '请先填写发票抬头',
          type: 'warning',
        });
        this.saveLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-invoice {
  font-size: 12px;
  .text {
    color: #999;
  }
  /deep/ .el-form {
    font-size: 12px;
    .el-form-item__label {
      font-size: 12px;
    }
    .el-radio__label {
      font-size: 12px;
    }
  }
  .el-input,
  .area-width {
    width: 300px;
  }
}
</style>
