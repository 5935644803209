// 常量 global在上 业务在下
export const FETCH_STATE = {
  // 请求状态
  INIT: -1, // 初始化
  LOADING: 0, // loading
  SUCCESS: 1, // 请求成功
  ERROR: 2 // 请求失败
};

export const AREA = {
  // 地区
  CHINA: '1' // 中国
};

export const CASH_TYPE = {
  // 现金券类型
  ALL: '1', // 无门槛
  FULL: '2' // 满减
};

export const DEFAULT_STATUS = {
  // 默认状态
  STOP: '0', // 未启用
  START: '1' // 启用
};

export const ENTERPRISE_TYPE = {
  // 企业类型
  ALL: '0', // 全部
  SINGLE: '1', // 个体
  NOT_SINGLE: '2' // 非个体
};

export const PUBLISH_TYPE = {
  // 发放形式
  BACKEND: '1', // 后台
  REGISTER: '2' // 注册
};

export const WITHDRAW_STATUS = {
  // 提现状态
  CONFIRM_WAIT: '0', // 待审核
  CONFIRM_NO_PASS: '1', // 审核不通过
  CONFIRM_PASS_WAIT: '2' // 审核通过 提现中
};

export const PROMOTION_STATUS = {
  // 促销状态
  STOP: '0', // 未启用
  START: '1' // 启用
};

export const PROMOTION_BONUS_TYPE = {
  // 促销 - 达量奖类型
  APPLIQUE: '1', // 贴花
  NUMBER: '2' // 商品数量
};

export const PROMOTION_PROFIT_TYPE = {
  // 促销 - 贴花类型
  ACCORDING_TO_COMMODITY: '1', // 按商品统计
  ACCORDING_TO_SALE: '2' // 按销售额统计
};

export const SHOP_STATUS = {
  // 店铺状态
  PASS: '1' // 审核通过
};

export const ENTERPRISE_STATUS = {
  // 企业状态
  PASS: '1' // 审核通过
};

export const CASH_STATUS = {
  // 现金券状态
  SAVED: '0', // 暂存
  EFFECTIVE: '1' // 已发放
};
export const GIFT_STATUS = {
  // 礼品卡状态
  SAVED: '0', // 暂存
  EFFECTIVE: '1' // 已发放
};

export const DICT_TYPE = {
  // 数据字典 类型
  ENTERPRISE_STATUS: 'enterprise_status', // 企业状态
  PURCHASE_ORDER_STATE: 'purchase_order_state', // 采购订单状态
  PURCHASE_ORDER_REFUND_STATUS: 'purchase_order_refund_status', // 采购订单退款状态
  ARTICLE_STATUS: 'article_status' // 政策支持状态
};
export const INFORMATION_PARENTID = {
  // 消息管理 类型
  PARENTID: 'zg-main'
};

export const BA_USER_STATUS = {
  // BA用户状态
  PASS: '1' // 审核通过
};

export const PURCHASE_ORDER_REFUND_STATUS = {
  // 订单退款状态
  NOT_REFUND: '0', // 未退款
  SUCCESS: '2' // 退款成功
};

export const PURCHASE_ORDER_STATE = {
  // 订单状态
  WAIT_TO_SEND: '1', // 待发货
  WAIT_TO_RECEIVE: '2', // 待收货
  COMPLETE: '3', // 交易完成
  WAIT_TO_VERIFY: '8' // 待审核
};

export const RECEIPT_STATUS = {
  // 小票状态
  WAITCONFIRM: '0' // 待审核
};

export const EXTRA_BONUS_STATUS = {
  // 贴花金额导入状态
  UPLOADED: '1',
  COMMITTED: '8'
};

// 商品类型
export const COMMODITY_TYPE = {
  ACTUAL: '实物',
  VIRTUAL: '虚拟',
  GLOBAL: '海淘商品',
  CARD: '卡券商品'
};
