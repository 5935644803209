import request from '@/utils/request';

export function listByParentsIds(ids) {
  const formData = new FormData();
  ids.forEach(id => formData.append('parents', id));
  return request({
    url: '/common/api/area/listAreaByParentIds',
    method: 'post',
    data: formData
  });
}

export function listAll(data) {
  return request({
    url: '/common/api/area/listAll',
    method: 'post',
    data
  });
}

