import { withExtTenantIdRequest } from '@/utils/request';

// 获取我的发票抬头
export function listMyPage(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/invoiceTitle/listMyPage',
    method: 'post',
    data
  });
}

// 默认开票抬头
export function getMyDefault() {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/invoiceTitle/getMyDefault',
    method: 'get'
  });
}

// 新增我的发票抬头
export function create(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/invoiceTitle/create',
    method: 'post',
    data
  });
}

// 修改我的发票抬头 
export function update(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/invoiceTitle/update',
    method: 'post',
    data
  });
}

// 删除我的发票抬头
export function invoiceDelete(id) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/invoiceTitle/delete?id=${id}`,
    method: 'post'
  });
}

// 批量删除我的发票抬头
export function deleteByIds(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/invoiceTitle/deleteByIds',
    method: 'post',
    data
  });
}

// 详情我的发票抬头
export function getById(id) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/invoiceTitle/get?id=${id}`,
    method: 'get'
  });
}

// 可开票列表品牌类型及可开票订单数
export function getInvoiceOrderType(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/invoiceApplyRecord/getInvoiceOrderType',
    method: 'post',
    data
  });
}

// 可开票列表
export function canInvoiceOrderPageList(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/merchantOrder/canInvoiceOrderPageList',
    method: 'post',
    data
  });
}

// 开票
export function invoiceCreate(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/invoiceApplyRecord/create',
    method: 'post',
    data
  });
}

// 开票记录列表
export function recordList(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/invoiceApplyRecord/myListPage',
    method: 'post',
    data
  });
}

// 开票记录详情
export function recordDeatil(id) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/invoiceApplyRecord/get?id=${id}`,
    method: 'get'
  });
}

// 根据企业名称获取企业税号
export function queryByName(val) {
  return withExtTenantIdRequest({
    url: `/ocean/api/companyIdentity/queryByName?companyName=${val}`,
    method: 'post'
  });
}

// 根据企业名称获取企业详细信息
export function queryInvoiceTitleInfoByName(val) {
  return withExtTenantIdRequest({
    url: `/ocean/api/companyIdentity/queryInvoiceTitleInfoByName?companyName=${val}`,
    method: 'post'
  });
}

// 全部开票按钮点击的时候查询全部开票的金额
export function invoiceApplyRecordAllInvoiceAmount(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/invoiceApplyRecord/allInvoiceAmount`,
    method: 'post',
    data
  });
}



export function getOrderDetailByPageForMerchant(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/invoiceApplyRecord/getOrderDetailByPageForMerchant',
    method: 'post',
    data
  });
}

// 字典
export function dictListByType (type) {
  return withExtTenantIdRequest({
    url: `/common/api/dict/listByType?type=${type}`,
    method: 'get'
  });
}

// 查询开票订单确认列表
export function getInvoiceOrderConfirmList(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/invoiceApplyRecord/invoiceOrderConfirmList',
    method: 'post',
    data
  });
}

// 确认可开票订单
export function canInvoiceOrderConfirm(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/invoiceApplyRecord/canInvoiceOrderConfirm',
    method: 'post',
    data
  });
}

// 有合同主体申请开票
export function createInBatch(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/invoiceApplyRecord/createInBatch',
    method: 'post',
    data
  });
}

// 无合同主体申请开票
export function createNotHaveContract(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/invoiceApplyRecord/create',
    method: 'post',
    data
  });
}

// 无主体订单选择发票抬头
export function getListForOrder(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/invoiceTitle/listForOrder',
    method: 'post',
    data
  });
}

// 开票主体列表查询
export function getListBriefForMerchant(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorContractInfo/listBriefForMerchant',
    method: 'post',
    data
  });
}