export default class Process {
  // 流程控制
  constructor() {
    this.promise = Promise.resolve();
    this.stacks = [];
  }

  stepBy(func) {
    // 多次异步调用，结果按顺序执行
    this.promise = this.promise.then(() => func());
    return this.promise;
  }
  /**
   * isThrowError 重复调用：是否抛出异常
   */
  takeLast(func, isThrowError) {
    // 多次异步调用，结果只处理最后一个
    const promise = new Promise((rs, rj) => {
      func()
        .then(res => {
          this.promise === promise && rs(res);
        })
        .catch(err => {
          this.promise === promise && rj(err);
        })
        .finally(() => {
          if (this.promise !== promise && isThrowError) {
            rj(new Error('repeat'));
          }
        });
    });
    this.promise = promise;
    return promise;
  }

  push(p) {
    this.stacks.push(p.catch(() => {}));
  }

  all() {
    return Promise.all(this.stacks);
  }

  reset() {
    this.promise = Promise.resolve();
  }

  clear() {
    this.promise = null;
  }
}
