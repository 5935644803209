<template>
  <el-cascader
    :value="data"
    @change="onChange"
    :options="options"
    @active-item-change="handleItemChange"
    :props="props"
    clearable
    :disabled="isDetail"
    :placeholder="placeholder"
  ></el-cascader>
</template>

<script>
import { listByParentsIds } from '@/api/common/area';
import * as CONSTANTS from '@/constants';
import Process from '@/utils/Process';
export default {
  name: 'area-cascader',
  props: {
    value: Array,
    placeholder: String,
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  mounted() {
    this.data = this.value;
    this.handleItemChange(this.data);
  },
  watch: {
    value(val) {
      if (val !== this.data) {
        this.handleItemChange(val).then(() => {
          this.data = val;
        });
      }
    },
  },
  data() {
    return {
      CONSTANTS,
      process: new Process(),
      data: null,
      dataMap: {},
      options: [],
      props: {
        value: 'id',
        children: 'children',
        label: 'name',
      },
    };
  },
  methods: {
    onChange(val) {
      this.data = val;
      this.$emit('change', val);
    },
    handleItemChange(val) {
      const list = [CONSTANTS.AREA.CHINA]; // 根节点 - 中国
      if (val) {
        list.push(...val);
      }
      const func = this.onValueChange.bind(this, list);
      return this.process.stepBy(func).then(() => {
        const map = this.dataMap[CONSTANTS.AREA.CHINA];
        if (!map) {
          this.options = [];
        } else {
          this.options = [...map.data.children]; // 触发observe
        }
      });
    },
    async onValueChange(params) {
      let dataMap = this.dataMap; // dataMap 代表当前索引位置
      for (let i = 0; i < params.length; i++) {
        // 异步函数 要注意流程控制 如果有并发 这里无法保障执行顺序 由于共享了dataMap 容易导致dataMap数据混乱
        dataMap = await this.getData(params[i], dataMap); // 通过循环 逐级向下返回索引
      }
    },
    async getData(id, dataMap) {
      if (!dataMap) {
        return;
      }
      if (!dataMap[id]) {
        // 如果索引不存在 创建空节点
        dataMap[id] = {};
      }
      const obj = dataMap[id];
      if (obj.children === null) {
        return null;
      }
      if (obj.children === undefined) {
        // 如果没有子节点 需要初始化数据
        obj.children = {};
        const dataList = await this.fetchDataList([id]);
        if (dataList.length === 0) {
          // 如果后端返回为空 则直接返回
          return null;
        }
        obj.data = { children: dataList };
      }
      // 给子节点创建索引 同时计算出 有哪些不确定的子节点
      const childrenData = obj.data.children;
      const children = obj.children;
      const ids = [];
      childrenData.forEach((item) => {
        if (!children[item.id]) {
          // 如果已经被索引 则忽略
          children[item.id] = { data: item };
        }
        if (children[item.id].children === undefined) {
          // undefined 代表不确定是否有子节点
          ids.push(item.id);
        }
      });

      if (ids.length === 0) {
        // 没有不确定子节点 直接返回子节点索引
        return children;
      }

      const childrenList = await this.fetchDataList(ids); //  批量查询所有子节点的子节点

      // 组装子节点的子节点 并索引
      childrenList.forEach((child) => {
        const parent = children[child.parentId];
        if (!parent) {
          return;
        }
        if (!parent.children) {
          parent.children = {};
        }
        if (parent.children[child.id]) {
          return;
        }
        parent.children[child.id] = { data: child };
        if (!parent.data.children) {
          parent.data.children = [];
        }
        parent.data.children.push(child);
      });

      ids.forEach((id) => {
        if (!children[id].children) {
          children[id].children = null; // null 代表确定没有子节点
        }
      });

      return children;
    },
    fetchDataList(ids) {
      return listByParentsIds(ids).then((res) => res.data);
    },
  },
};
</script>
